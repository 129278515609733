<template>
    <div class="loader-container">
        <div class="loader"></div>
    </div>
</template>

<script>
export default {
    name: "VerifyAuth",
}
</script>

<style lang="scss" scoped>

.loader-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
    margin: auto;
    border: 1em solid #D9D9D9;
    border-top: 1em solid #3579f6;
    border-radius: 50%;
    width: 8em;
    height: 8em;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
